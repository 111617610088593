export const PATH = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/signup',
  CONTACT: '/contact',
  ABOUT: '/about',
  DEMO1: '/demo1',
  DEMO2: '/demo2',
  FEATURE1: '/feature1',
  FEATURE2: '/feature2',
  PROFILE: '/profile',
  PRODUCTS: '/products',
  PRODUCT_SHOW: '/products/:id',
  PRODUCT_NEW: '/products/new',
  PRODUCT_EDIT: '/products/:id/edit',
};
